// import stylesheets
import '/src/assets/sass/main.sass'

// import npm packages
import Swup from 'swup'
import SwupRouteNamePlugin from '@swup/route-name-plugin'
// import SwupScrollPlugin from '@swup/scroll-plugin'
import mobile from 'is-mobile'

import * as L from 'leaflet'
import 'leaflet-active-area'

import 'add-to-calendar-button'

// import data
import spaces from '/public/data/spaces.json'

const swup = new Swup({
	plugins: [
		new SwupRouteNamePlugin({
   routes: [
    { name: 'home', path: '/' },
    { name: 'spaces', path: '/spaces' },
    { name: 'events', path: '/events' },
    { name: 'info', path: '/info' }
   ]
	 }),
	 // new SwupScrollPlugin({
	 // 	offset: 15
	 // })
	]
})

// netlify identity function
const netlifyIdentity = () => {
	if (window.netlifyIdentity) {
		window.netlifyIdentity.on("init", user => {
	  if (!user) {
    window.netlifyIdentity.on("login", () => {
     document.location.href = "/admin/";
    });
	  }
		});
	}
}

// global variables
let navLinks = [...document.getElementsByClassName('nav-link')]
let map
let markers = []
let activearea
let sidebar = document.getElementsByClassName('content')[0]
let welcome = document.getElementsByClassName('welcome-banner')[0]

// initialize leaflet map
const initMap = (callback) => {
	const map_options = {
	 center: [48.2082, 16.3738],
	 zoom: mobile() ? 12 : 13,
	 zoomControl: false,
	 minZoom: 11,
	 tap: false
	}

	const tile_src = "https://api.mapbox.com/styles/v1/{id}/tiles/256/{z}/{x}/{y}@2x?access_token={accessToken}"
	// const tile_src = "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
	const tile_options = {
		attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
		// attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
		maxZoom: 18,
		// maxZoom: 16
		// id: 'independentspaceindex/ckq0l4uvd0is918k0ksrqdrcq',
		id: 'independentspaceindex/clhetccb1018x01p67l2ffkyq',
		accessToken: 'pk.eyJ1IjoiaW5kZXBlbmRlbnRzcGFjZWluZGV4IiwiYSI6ImNrNW1tbjA5bDAza24zZXBkanFlc2ppcTQifQ.JnL1exWS78WBLlZrXn6BuQ'
	}
	
	// const popup_options = {
	// 	offset: [15, 0]
	// }

	const addClass =(m, cl)=> m._icon.classList.add(cl)
	const rmClass =(m, cl)=> m._icon.classList.remove(cl)
	
	const resetMarkers =()=> {
		markers.forEach(e => {
			rmClass(e.marker, "clicked")
			rmClass(e.marker, "active")
			e.marker.setZIndexOffset(0)
		})
	}

	const isClicked =(m)=> m._icon.className.split(" ").includes("clicked")

	// create leaflet map
	map = new L.Map('map', map_options).setActiveArea('active-area')

	activearea = document.getElementsByClassName('active-area')[0]
	
	// customize leaflet attribution
	// map.attributionControl.setPrefix('<a href="https://leafletjs.com/" target="_blank">Leaflet</a>')
	
	map.on('click',	resetMarkers)

	// add tiles
	L.tileLayer(tile_src, tile_options).addTo(map);

	// cycle through data/spaces.json and add markers for the spaces
	for (let i = spaces.length - 1; i >= 0; i--) {
		let circleIcon = L.divIcon({
			className: "circle-icon",
			iconAnchor: [15,15],
			iconSize: null,
			html: `<div class="mobile">+</div><div class="highlight"></div><span>${spaces[i].name}</span>`
		})
		
		if (spaces[i].hidden != "true") {
			let marker = new L.Marker([spaces[i].lat, spaces[i].lng], {
				icon: circleIcon,
				riseOnHover: true,
				riseOffset: 1000
			})
			.addTo(map)
			// marker behaviour
			.on('click', () => {
				let is_clicked = isClicked(marker)
				if (mobile()) {
					resetMarkers()
					if (is_clicked) {
						setView('/spaces/' + spaces[i].uid)
					} else {
						setMapView(spaces[i].uid)
						addClass(marker, "clicked")
						addClass(marker, "active")
					}
				} else {
					if (!isClicked(marker)) {
						resetMarkers()
						// setMapView(spaces[i].uid)
						addClass(marker, "clicked")
						addClass(marker, "active")
						setView('/spaces/' + spaces[i].uid)
					}
				}	
				marker.setZIndexOffset(500)
			})
			.on('mouseover', () => {
				if (!mobile()) {
					if (!isClicked(marker)) {
						addClass(marker, "active")
					}
				}
			})
			.on('mouseout', () => {
				if (!mobile()) {
					markers.forEach((e) => {
							!isClicked(e.marker) ? rmClass(e.marker, "active") : false
						})
				}
			})

			markers.push({
				"uid" : spaces[i].uid,
				"marker" : marker
			})
		}

	}

	callback()
}

const setMapView = (uid) => {

	// console.log(sidebar.clientWidth)
	// sidebar.clientWidth > 798 ? activearea.style.width = "calc(100% - 798px)" : false
	map.setActiveArea('active-area')

	let space = spaces.filter(item => item.uid == uid)
	let marker = markers.filter(item => item.uid == uid)
	markers.forEach((e) => {
		e.marker._icon.classList.remove("clicked")
		e.marker._icon.classList.remove("active")
		e.marker.setZIndexOffset(0)
	})
	marker[0].marker._icon.classList.add("clicked")
	marker[0].marker._icon.classList.add("active")
	marker[0].marker.setZIndexOffset(500)
	map.panTo([space[0].lat, space[0].lng])
}

const checkView = () => {
	// reset scroll position
	// swup.scrollTo(0, false)
	window.scrollTo(0, 0)
	
	let loc = window.location

	if (loc.hash) {
		let href = loc.hash.split("#")[1]
		let space = spaces.filter(item => item.uid == href)
		space.length ? setMapView(space[0].uid) : false
	
	} else {
		let href = loc.href.split('/spaces/')
		href = href[href.length - 1].replace('/', '').replace('#', '')

		if (href) {
			let space = spaces.filter(item => item.uid == href)
			space.length ? setMapView(space[0].uid) : false
		}
	}


	updateNav(loc)
}

// set page view with swup
const setView = (href) => {
	swup.loadPage({
	  url: href
	})
}

// highlight active nav link
const updateNav = (loc) => {
	resetNav()

	let path = loc.pathname.split('/')[1]
	path = path == "" ? "map" : path
	
	let current = navLinks.filter(e => {
		return e.innerText.toLowerCase() == path
	})

	if (current[0].innerText == "MAP") {
		!mobile() ? centerMap() : false
		// centerMap()
	}

	current[0].classList.add('active')
}

// reset nav link highlights
const resetNav = () => {
	navLinks.forEach(link => {
		link.classList.remove('active')
	})
}

const centerMap = () => {
	map.setActiveArea('map')
	map.setView([48.2082, 16.3738], 13, {
		// duration: 0.5
	})
}

const initExcerpts = () => {
	[...document.getElementsByClassName('excerpt-button')].forEach(e => {
		e.addEventListener('click', () => {
			e.parentElement.parentElement.classList.toggle("show")
		})
	})
}

window.addEventListener('click', () => {
	welcome.style.transform = 'translateX(-100vw)'
})


// add event listeners
swup.on("pageView", (e) => {
	checkView()
})
swup.on("pageRetrievedFromCache", () => {
	// wait 0ms for the url to be updated
	setTimeout(checkView, 0)
})
swup.on("samePageWithHash", (e) => {
	let uid = e.srcElement.hash.split("#")[1]
	setMapView(uid)
})
swup.on("contentReplaced", (e) => {
	initExcerpts()
})



// functions called on the first load
initMap(checkView)
initExcerpts()
netlifyIdentity()
